import React from "react";

import Layout from "../components/layout";
import SEO from "../components/seo";

const NotFoundPage = () => (
  <Layout>
    <SEO title="404: Not found" />
    <div className="max-w-screen-md mx-auto py-2">
      <h1 className="text-xl font-bold pb-4">Not found!</h1>
    </div>
  </Layout>
);

export default NotFoundPage;
